import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { DarkTheme } from './styled/themes';

import { setContext } from '@apollo/client/link/context'; // Add this import

// Page imports
import Home from './pages/App';
import TOS from './pages/TOS';
import Privacy from './pages/Privacy';
import Search from './pages/Search';
import Dashboard from './pages/Dashboard';
import ServerManage from './components/dashboard/ServerManage';
import StaffRouteGuard from './components/routes/StaffRouteGuard';  // Add this import
import StaffOfficeView from './components/management/StaffOfficeView';  // Add this import
import PersonnelFiles from './components/management/features/personnel/PersonnelFiles';  // Add this import
import UnitManagement from './components/management/features/units/UnitManagement';  // Add this import

import LinkedRoles from './pages/LinkedRoles';
import Xbox from './pages/Xbox';
import AuthCallback from './components/api/ExchangeToken';
import { setAuthToken, getAuthToken } from './components/api/auth';

// Apollo Client configuration
// ApolloClient configuration (for your React app)
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { from } from '@apollo/client';

const httpLink = createHttpLink({
    uri: 'https://milstar.glitch.me/api',
    credentials: 'include'
});

// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    if (networkError) console.error(`[Network error]: ${networkError}`);
});

const authLink = new ApolloLink((operation, forward) => {
    const token = getAuthToken();
    console.log('Apollo operation:', operation.operationName);
    console.log('Using token:', token);
    
    operation.setContext(({ headers = {} }) => {
        const newHeaders = {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ''
        };
        console.log('Setting headers:', newHeaders);
        return { headers: newHeaders };
    });
    
    return forward(operation);
});

const client = new ApolloClient({
    uri: 'https://milstar.glitch.me/api',
    credentials: 'include',
    link: authLink.concat(createHttpLink({
        uri: 'https://milstar.glitch.me/api'
    })),
    cache: new InMemoryCache()
});

// Microsoft Auth Configuration
const MICROSOFT_CONFIG = {
    associatedApplications: [
        {
            applicationId: "20414e1a-9051-4787-aa21-cc6ef93a51ad"
        }
    ]
};

// Root App Component
const App = () => {
    return (
        <Router>
            <ThemeProvider theme={DarkTheme}>
                <ApolloProvider client={client}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/tos" element={<TOS />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/:serverId" element={<ServerManage />} />
                        <Route path="/dashboard/:serverId/staff" element={<StaffRouteGuard />}>
                            <Route path=":officeCode" element={<StaffOfficeView />} />
                            <Route path=":officeCode/personnel" element={<PersonnelFiles />} />
                            <Route path=":officeCode/units" element={<UnitManagement />} />
                        </Route>
                        <Route path="/xbox/:xuid" element={<Xbox />}/>
                        <Route path="/linked-roles" element={<LinkedRoles />} />
                        <Route path="/auth-callback" element={<AuthCallback />} />
                        <Route 
                            path="/.well-known/microsoft-identity-association.json" 
                            element={<Navigate to={MICROSOFT_CONFIG} replace />} 
                        />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </ApolloProvider>
            </ThemeProvider>
        </Router>
    );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

export { MICROSOFT_CONFIG };