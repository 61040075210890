import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_OFFICE_ACTIVITY } from '../../graphql/queries';
import { Shield, AlertCircle } from 'lucide-react';
import { Card } from '../ui/card';

const ActivityFeed = ({ serverId, officeCode }) => {
  const { loading, error, data } = useQuery(GET_OFFICE_ACTIVITY, {
    variables: { 
      serverId, 
      officeCode: officeCode?.toUpperCase(), // Ensure office code is uppercase
      limit: 10 
    },
    pollInterval: 30000
  });

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-4">
            <div className="animate-pulse space-y-3">
              <div className="flex items-center justify-between">
                <div className="h-4 bg-neutral-700 rounded w-1/4"></div>
                <div className="h-4 bg-neutral-700 rounded w-1/5"></div>
              </div>
              <div className="h-4 bg-neutral-700/50 rounded w-3/4"></div>
              <div className="flex items-center gap-2">
                <div className="h-4 bg-neutral-700/30 rounded w-1/6"></div>
                <div className="h-4 bg-neutral-700/30 rounded w-1/4"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <Card className="p-4 border-red-500/20 bg-red-500/5">
        <div className="flex items-center gap-2 text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error.message}</p>
        </div>
      </Card>
    );
  }

  const activities = data?.getOfficeActivity || [];

  if (activities.length === 0) {
    return (
      <Card className="p-6 bg-neutral-800/30 border-neutral-700/50">
        <div className="flex flex-col items-center justify-center text-neutral-400 py-6">
          <Shield className="w-12 h-12 mb-3 text-neutral-500" />
          <p>No recent activity to display</p>
        </div>
      </Card>
    );
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="space-y-4">
      {activities.map((activity, index) => (
        <div
          key={index}
          className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-4 transform hover:scale-[1.02] transition-transform"
        >
          <div className="flex items-center justify-between mb-2">
            <span className={`font-medium ${
              activity.type === 'MAJOR' ? 'text-cyan-300' : 'text-neutral-300'
            }`}>
              {activity.action}
            </span>
            <span className="text-neutral-400 text-sm">
              {formatTimestamp(activity.timestamp)}
            </span>
          </div>
          {activity.details && (
            <p className="text-neutral-300 text-sm mt-2 font-mono bg-neutral-900/30 p-2 rounded">
              {activity.details}
            </p>
          )}
          <div className="flex items-center gap-2 mt-3">
            <span className="text-neutral-400 text-sm">
              By <span className="text-neutral-300">@{activity.userId}</span>
            </span>
            {activity.type === 'MAJOR' && (
              <span className="px-2 py-0.5 bg-cyan-900/20 text-cyan-300 text-xs rounded-full border border-cyan-500/20">
                Major Action
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActivityFeed;