// Search.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, DiscordAuth } from '../components';
import Dropdown from '../styled/dropdown';
import { FormItemContainer, FormTextItem, Button, DropdownItem, DropdownItemContainer } from '../styled/styles';
import GetUsers from '../components/api/GetUsers';
import '../App.css';
import '../index.css';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPlatform, setSearchPlatform] = useState('All Platforms');
  const [searchVisible, setSearchVisible] = useState(false);
  const [activeSearch, setActiveSearch] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('https://milstar.glitch.me/auth/status', {
          credentials: 'include'
        });
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setActiveSearch(searchTerm.trim());
  };

  if (!isAuthenticated) {
    return (
      <div className='Search'>
        <div className='gradient__bg'>
          <Navbar />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
          gap: '1rem',
          textAlign: 'center'
        }}>
          <h2 style={{
            color: 'white',
            fontFamily: 'var(--font-text)',
            marginBottom: '1rem'
          }}>
            Please sign in to use the search feature
          </h2>
          <DiscordAuth />
        </div>
      </div>
    );
  }

  return (
    <div className='Search'>
      <div className='gradient__bg'>
        <Navbar />
      </div>
      <FormItemContainer>
        <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <label htmlFor="search">Search</label>
          <FormTextItem
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text" 
            placeholder='Gamertag'
          />
          <Dropdown
            options={['Xbox', 'Discord', 'Social Club', 'All Platforms']}
            value={searchPlatform}
            onChange={setSearchPlatform}
          />
          <Button type="submit">
            Submit
          </Button>
        </form>
      </FormItemContainer>
      <div className='milstar__starid-users section__padding'>
        <GetUsers search={activeSearch} platform={searchPlatform} />
      </div>
    </div>
  );
};

export default Search;