import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_STAFF_OFFICE_DETAILS } from '../../graphql/queries';

const StaffRouteGuard = () => {
  const { serverId, officeCode } = useParams();
  
  const { loading, error, data } = useQuery(GET_STAFF_OFFICE_DETAILS, {
    variables: { serverId, officeCode: officeCode?.toUpperCase() },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-neutral-900">
        <div className="w-8 h-8 border-t-2 border-cyan-500 rounded-full animate-spin" />
      </div>
    );
  }

  if (error || !data?.staffOfficeDetails) {
    return (
      <div className="p-8 bg-neutral-900">
        <div className="p-6 bg-neutral-800/50 border-red-500/20 rounded-lg">
          <h2 className="text-lg font-bold text-red-400">Error</h2>
          <p className="text-neutral-400 mt-2">
            {error ? error.message : 'This office does not exist or you don\'t have access to it.'}
          </p>
        </div>
      </div>
    );
  }

  return <Outlet />;
};

export default StaffRouteGuard;