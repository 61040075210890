import styled from 'styled-components';

const AlertContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background: ${props => props.variant === 'destructive' ? 'rgba(220, 38, 38, 0.1)' : 'rgba(6, 182, 212, 0.1)'};
  border: 1px solid ${props => props.variant === 'destructive' ? 'rgba(220, 38, 38, 0.2)' : 'rgba(6, 182, 212, 0.2)'};
  color: ${props => props.variant === 'destructive' ? '#ef4444' : '#22d3ee'};
  margin-bottom: 1rem;
`;

export { AlertContainer }