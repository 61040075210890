import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { setAuthToken, getAuthToken } from '../api/auth';

const GET_ME = gql`
  query GetMe {
    me {
      duid
      discord {
        username
        avatar
        guilds {
          name
          icon
        }
      }
    }
  }
`;

const DiscordAuth = ({ onAuthChange }) => {
  const [authStatus, setAuthStatus] = useState(null);
  const { loading, error, data, refetch } = useQuery(GET_ME, {
    fetchPolicy: 'network-only',
    skip: !authStatus?.authenticated // Skip the query if not authenticated
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = getAuthToken();
        console.log('Checking auth with token:', token);
        
        if (!token) {
          console.log('No token found, setting unauthenticated');
          setAuthStatus({ authenticated: false });
          onAuthChange?.(false);
          return;
        }
        
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };
        
        console.log('Auth check headers:', headers);
        
        const response = await fetch('https://milstar.glitch.me/auth/status', {
          credentials: 'include',
          headers
        });
        
        console.log('Auth check response:', response.status);
        const status = await response.json();
        console.log('Auth check status:', status);
        
        setAuthStatus(status);
        onAuthChange?.(status.authenticated);
        
        if (!status.authenticated) {
          console.log('Not authenticated, clearing token');
          setAuthToken(null);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setAuthStatus({ authenticated: false });
        setAuthToken(null);
        onAuthChange?.(false);
      }
    };

    checkAuth();
  }, [onAuthChange]);

  // Handle login click
  const handleLogin = () => {
    window.location.href = 'https://milstar.glitch.me/auth/discord';
  };

  // Handle logout click
  const handleLogout = async () => {
    try {
      setAuthToken(null); // Clear token first
      await fetch('https://milstar.glitch.me/auth/logout', {
        credentials: 'include'
      });
      setAuthStatus({ authenticated: false });
      onAuthChange?.(false);
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Show loading state while checking auth
  if (!authStatus) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300" />
      </div>
    );
  }

  // Show login button if not authenticated
  if (!authStatus.authenticated) {
    return (
      <button 
        onClick={handleLogin}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.75rem',
          backgroundColor: '#5865F2',
          color: 'white',
          padding: '0.625rem 1.5rem',
          borderRadius: '0.375rem',
          fontFamily: 'var(--font-text)',
          fontWeight: 'bold',
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          border: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.2s',
          WebkitTapHighlightColor: 'transparent'
        }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#4752C4'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#5865F2'}
      >
        <svg style={{
          width: '1.5rem',
          height: '1.5rem',
          fill: 'currentColor'
        }} viewBox="0 -28.5 256 256">
          <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z" />
        </svg>
        <span style={{ display: 'inline-block' }}>Sign in</span>
      </button>
    );
  }

  // Show loading state while fetching user data
  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300" />
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center p-4 gap-2">
        <div className="text-red-500 text-sm mb-2">
          Error loading user data
        </div>
        <button
          onClick={refetch}
          className="px-4 py-2 bg-neutral-700 text-neutral-100 rounded-md text-sm hover:bg-neutral-600"
        >
          Retry
        </button>
      </div>
    );
  }

  // Show user info if authenticated and data is loaded
  const { discord } = data.me;
  let avatar = discord.avatar;
  return (
    <div style={{
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      padding: '1rem',
      WebkitTapHighlightColor: 'transparent'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
      }}>
        {avatar ? (
          <img 
            src={`https://cdn.discordapp.com/avatars/${data.me.duid}/${avatar}.png`} 
            alt={discord.username}
            style={{
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '50%'
            }}
          />
        ) : (
          <div style={{
            width: '2.5rem',
            height: '2.5rem',
            borderRadius: '50%',
            backgroundColor: '#e5e7eb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <span style={{
              color: '#6b7280',
              fontSize: '1.125rem'
            }}>
              {discord.username[0].toUpperCase()}
            </span>
          </div>
        )}
        <div>
          <div style={{
            fontFamily: 'var(--font-text)',
            fontWeight: '500',
            letterSpacing: '0.1em'
          }}>
            {discord.username}
          </div>
          <div style={{
            fontSize: '0.875rem',
            color: '#6b7280',
            fontFamily: 'var(--font-text)'
          }}>
            {discord.guilds?.length || 0} Servers
          </div>
        </div>
        <button
          onClick={handleLogout}
          style={{
            marginLeft: 'auto',
            padding: '0.25rem 0.75rem',
            fontSize: '0.875rem',
            color: '#4b5563',
            backgroundColor: 'transparent',
            borderRadius: '0.375rem',
            border: 'none',
            cursor: 'pointer',
            fontFamily: 'var(--font-text)',
            transition: 'all 0.2s',
            WebkitTapHighlightColor: 'transparent'
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#f3f4f6';
            e.target.style.color = '#1f2937';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#4b5563';
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default DiscordAuth;