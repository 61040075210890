// Import existing PersonnelFiles component
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import PersonnelFilesComponent from './PersonnelFilesComponent'; // Rename your existing component

const PersonnelFiles = () => {
  const { serverId, officeCode } = useParams();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-neutral-900 p-6">
      {/* Header Section */}
      <div className="mb-8">
        <button
          onClick={() => navigate(`/dashboard/${serverId}/staff/${officeCode}`)}
          className="flex items-center text-neutral-400 hover:text-cyan-300 transition-colors mb-6"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Office
        </button>

        <div className="bg-gradient-to-br from-neutral-800/90 to-neutral-900/90 rounded-lg border border-cyan-300/50 p-6">
          <h1 className="text-2xl font-bold text-neutral-100">Personnel Files</h1>
          <p className="text-neutral-400 mt-1">Manage personnel records and information</p>
        </div>
      </div>

      {/* Personnel Files Component */}
      <PersonnelFilesComponent serverId={serverId} officeCode={officeCode} />
    </div>
  );
};

export default PersonnelFiles;