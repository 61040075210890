import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Navbar, DiscordAuth } from '../components';
import { FormItemContainer, Button, TextHeading } from '../styled/styles';
import { GET_MANAGEABLE_SERVERS, GET_SERVER_DETAILS } from '../graphql/queries';
import { getAuthToken } from '../components/api/auth';

const ServerCard = ({ server }) => {
  const navigate = useNavigate();
  
  return (
    <div style={{
      background: 'linear-gradient(to bottom right, rgba(42, 42, 43, 0.9), rgba(50, 52, 53, 0.9))',
      padding: '1.5rem',
      borderRadius: '0.5rem',
      border: '1px solid rgba(6, 182, 212, 0.5)',
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
      }}>
        {server.icon ? (
          <img
            src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`}
            alt=""
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%'
            }}
          />
        ) : (
          <div style={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: '#374151',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <span style={{
              fontSize: '1.25rem',
              color: '#9CA3AF'
            }}>
              {server.name.charAt(0)}
            </span>
          </div>
        )}
        <div style={{
          flex: 1,
          minWidth: 0
        }}>
          <h3 style={{
            color: 'white',
            fontSize: '1.125rem',
            fontWeight: '600',
            marginBottom: '0.25rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            {server.name}
          </h3>
          <p style={{
            color: '#9CA3AF',
            fontSize: '0.875rem'
          }}>
            {server.memberCount.toLocaleString()} members
          </p>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <button
          onClick={() => navigate(`/dashboard/${server.id}`)}
          style={{
            width: '100%',
            padding: '0.5rem 1rem',
            backgroundColor: '#5865F2',
            color: 'white',
            borderRadius: '0.375rem',
            border: 'none',
            cursor: 'pointer',
            fontSize: '0.875rem',
            fontWeight: '500',
            fontFamily: 'var(--font-text)',
            letterSpacing: '0.1em',
            transition: 'background-color 0.2s'
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#4752C4'}
          onMouseLeave={(e) => e.target.style.backgroundColor = '#5865F2'}
        >
          Manage
        </button>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery(GET_MANAGEABLE_SERVERS, {
    fetchPolicy: 'network-only',
    skip: !isAuthenticated, // Skip query if not authenticated
    onError: (error) => {
      console.error('GraphQL error:', error);
      if (error.message.includes('Unauthorized') || error.message.includes('not authenticated')) {
        setIsAuthenticated(false);
      }
    }
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const token = getAuthToken();
        const headers = {
          'Content-Type': 'application/json'
        };
        
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch('https://milstar.glitch.me/auth/status', {
          credentials: 'include',
          headers
        });
        const data = await response.json();
        
        console.log('Auth status:', data);
        setIsAuthenticated(data.authenticated);
        
        if (data.authenticated) {
          refetch(); // Refetch server data when authenticated
        }
      } catch (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []); // Run once on mount

  const handleAuthChange = (newAuthState) => {
    console.log('Auth state changed:', newAuthState);
    setIsAuthenticated(newAuthState);
    if (newAuthState) {
      refetch(); // Refetch server data when auth state changes
    }
  };

  const handleAddBot = () => {
    window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=YOUR_CLIENT_ID&permissions=8&scope=bot%20applications.commands';
  };

  if (isLoading) {
    return (
      <div className="Dashboard">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300" />
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="Dashboard">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="flex flex-col items-center justify-center p-8 gap-4 text-center">
          <h2 className="text-neutral-100 font-text text-xl mb-4">
            Please sign in to access the dashboard
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div style={{
      minHeight: '100vh',
      backgroundColor: '#111827'
    }}>
      <div className="gradient__bg">
        <Navbar />
      </div>
      
      <div style={{
        padding: '2rem',
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        <div style={{
          textAlign: 'center',
          marginBottom: '2rem'
        }}>
          <h1 style={{
            fontSize: '1.875rem',
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '0.5rem'
          }}>
            Your Servers
          </h1>
          <p style={{
            color: '#9CA3AF',
            marginBottom: '1rem'
          }}>
            Manage your Discord servers
          </p>
          <button
            onClick={handleAddBot}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#5865F2',
              color: 'white',
              borderRadius: '0.375rem',
              border: 'none',
              cursor: 'pointer',
              fontSize: '0.875rem',
              fontWeight: '500',
              transition: 'background-color 0.2s'
            }}
            onMouseEnter={(e) => e.target.style.backgroundColor = '#4752C4'}
            onMouseLeave={(e) => e.target.style.backgroundColor = '#5865F2'}
          >
            Add Bot to Server
          </button>
        </div>

        {loading ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '2rem'
          }}>
            <div style={{
              width: '32px',
              height: '32px',
              border: '2px solid #9CA3AF',
              borderTop: '2px solid transparent',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite'
            }} />
          </div>
        ) : error ? (
          <div style={{
            textAlign: 'center',
            color: '#EF4444',
            fontSize: '1.125rem',
            padding: '2rem'
          }}>
            Error: {error.message}
          </div>
        ) : (
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '1.5rem',
            justifyItems: 'center',
            maxWidth: '1200px',
            margin: '0 auto'
          }}>
            {data?.manageableServers?.map(server => (
              <ServerCard key={server.id} server={server} />
            ))}
            {!data?.manageableServers?.length && (
              <div style={{
                gridColumn: '1 / -1',
                textAlign: 'center',
                padding: '2rem',
                color: '#9CA3AF',
                fontSize: '1.125rem'
              }}>
                No servers found. Add the bot to a server where you have management permissions.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;