import styled, { css } from 'styled-components';

export const SidebarStyle = styled.div`
    width: 100px;
    height: 100%;
    position: fixed;
    overflow: scroll;
    background-color: ${(props) => props.theme.base.sidebar};
    color: ${({ theme }) => theme.text.main};
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const MenuStyle = styled.div`
    width: 300px;
    background-color: ${({ theme }) => theme.base.menu};
    color: ${({ theme }) => theme.text.main};
    height: 100%;
    box-sizing: border-box;
    position: fixed;
    margin-left: 100px;
    overflow: scroll;
    text-transform: uppercase;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const BasePageStyle = styled.div`
    height: 100%;
    background-color: ${({ theme }) => theme.base.content};
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.text.main};
    box-sizing: border-box;
    position: absolute;
    margin-left: 400px;
    width:  calc(100% - 400px);
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const BaseHeader = styled.header`
    box-sizing: border-box;
    height: ${({ theme }) => theme.height.topContent};
    box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
`;

export const MenuHeader = styled
    (BaseHeader)`
    padding: 25px;
    position: fixed;
    z-index: 10;
    background-color: inherit;
    width: 100%;
`;

export const IconStyle = styled.div`
    height: 55px;
    width: 55px;
    background: grey;
    border-radius: 50px; 
`;

export const GuildIcon = styled(IconStyle)`
    height: 60px;
    width: 60px;
    margin: 5px 0px;
`;

export const SidebarHeader = styled
(BaseHeader)`
    justify-content: center;
`;

export const DashboardHeader = styled
    (BaseHeader)`
    padding: 25px;
`;


export const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({theme}) => theme.padding.content};
`;

export const MenuContent = styled.div`
    box-sizing: border-box;
    padding: ${({ theme }) => theme.padding.content};
    margin-top: 100px;
`;

export const MenuCategory = styled.div`
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0px;
    span {
        padding: 0px 15px;
    }
`;


export const MenuCategoryItem = styled.a`
    font-size: 20px;
    font-weight: bold;
    transition: 100ms background-color;
    &:hover {
        cursor: pointer;
        color: #EE7600 !important;
        text-shadow: 0px 0px 7px rgba(243, 159, 76, .6);
    }
`;

export const DashboardContent = styled.div`
    padding: 20px 20px;
`;

export const FormHeader = styled.div`
    background-color: #4f5b66;
    padding: 8px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 20px;
    font-size: 26px;
    display: flex;
    user-select: none;
    justify-content: space-between;
`;

export const FormItemContainer = styled.div`
    transition: 300ms;
    background-color: #4f5b66;
    padding: 20px;
    display: flex;
    flex-wrap: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

export const FormTextItem = styled.input`
    font-size: 18px;
    font-weight: regular;
    color: #FFFFFF;
    font-color: #FFFFFF;
    letter-spacing: 0.12em;
    border-style: solid;
    padding: 10px 20px;
    margin: 0px 20px;
    border-radius:10px 20px 10px 20px;
    border-width: 0px;
    border-color: rgb(0, 80, 80);
    font-family: "Agency FB";
    outline: none;
    background-color: #65737e;
    transition: 100ms;
    &::placeholder {
        color: #c2c2c2;
    }
    &:focus {
        border-width: 3px;
        border-color: rgb(128, 255, 255);
        //border-radius:10px 10px 10px 10px;
    }
`;

export const FormFooter = styled.div`
    background-color: #4f5b66;
    padding: 8px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 10px;
    max-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DropdownHeader = styled.div`
    background-color: #819a9d;
    padding: 8px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: "Agency FB";
    font-size: 16px;
    letter-spacing: 0.12em;
    cursor: pointer;
    display: flex;
    user-select: none;
    justify-content: space-between;
    border-radius: ${props => props.expanded ? '5px 5px 0 0' : '4px'};
`;

export const DropdownItemContainer = styled.div`
    max-height: 0px;
    overflow-y: scroll;
    opacity: 0;
    transition: 300ms;
    ${(props) => props.expanded ? css`
        max-height: 220px;
        opacity: 1;
        border-bottom-left-radius: 5px;
    ` : css` 
        max-height: 0;
        opacity: 0;`}

    &::-webkit-scrollbar {
        width: 10px;
        background:rgb(174, 192, 209);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a7adba;
        border-radius: 25px;
    }
`;

export const DropdownItem = styled.div`
    background-color:rgb(95, 125, 129);
    color: white;
    font-family: "Agency FB";
    letter-spacing: 0.12em;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding: 5px 25px;
    user-select: none;
    &:hover {
        color: #202225;
        font-weight: bold;
        background-color: #a2c1c5;
        cursor: pointer;
    }
    ${(props) =>
        props.isSelected &&
        css`
            background-color: #819a9d;
            color: #202225;
            font-weight: bold;
    `}
`;

export const DropdownFooter = styled.div`
    background-color: #4f5b66;
    padding: 8px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;




export const MultiChoiceHeader = styled.div`
    background-color: #4f5b66;
    padding: 8px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 20px;
    font-size: 26px;
    display: flex;
    user-select: none;
    justify-content: space-between;
`;

export const MultiChoiceContainer = styled.div`
    background-color: #4f5b66;
`;


export const MultiChoiceFooter = styled.div`
    background-color: #4f5b66;
    padding: 8px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    max-height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;




export const Button = styled.button`
    font-family: "Agency FB";
    margin: 1rem;
    padding: 5px 12px;
    background-color: inherit;
    border: 1px solid rgba(128, 255, 255, 0.15);
    outline: none;
    color: rgb(128, 255, 255);
    border-radius: 5px;
    transition: all 0.2s ease !important;
    user-select: none;

    &:hover {
        background-color: rgba(128, 255, 255, 0.1);
        border-color: rgb(128, 255, 255);
        box-shadow: 0 0 10px rgba(128, 255, 255, 0.2);
        cursor: pointer;
    }

    &:active {
        background-color: rgba(128, 255, 255, 0.2);
        color: rgb(128, 255, 255);
    }

    &:disabled {
        background-color: #919191;
        color: grey;
        border: none;
    }
`;

export const NavbarButton = styled.a`
    font-family: "Agency FB";
    font-size: 20px;
    padding: 10px 12px;
    background-color: inherit;
    border: 1px solid rgba(255, 255, 255, 0.15);
    outline: none;
    color: white;
    border-radius: 5px;
    transition: 100ms;
    user-select: none;
    &:hover {
        background-color: #a2c1c5;
        cursor: pointer;
    }
    &:active {
        background-color: #6dbcae;
    }
    &:disabled {
        background-color: #919191;
        color: grey;
        border: none;
    }
`;

export const TextHeading = styled.div`
    font-family: "Agency FB";
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.25);
    padding: 4px 8px;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: inherit;
`;

export const Input = styled.input`
    font-family: "Agency FB";
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.25);
    padding: 4px 8px;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: inherit;
`;


export const ScrollBoxStyle = styled.div`
    height: 30px;	
    overflow: hidden;
    position: relative;
    margin: 0;
    left: 0px;
    bottom: -200px;
    background: ${({ theme }) => theme.base.alt};
    color: ${({ theme }) => theme.text.alt};
`;

export const ScrollText = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 30px;
    text-align: center;

    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);	
    transform:translateX(100%);

    -moz-animation: scroll-left 5s linear infinite;
    -webkit-animation: scroll-left 5s linear infinite;
    animation: scroll-left 15s linear infinite;
    @-moz-keyframes scroll-left {
        0% { -moz-transform: translateX(100%); }
        100% { -moz-transform: translateX(-100%); }
    }
    @-webkit-keyframes scroll-left {
        0% { -webkit-transform: translateX(100%); }
        100% { -webkit-transform: translateX(-100%); }
    }
    @keyframes scroll-left {
        0% { 
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%); 
        }
        100% { 
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%); 
        }
    }
`;
