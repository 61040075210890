import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Card } from '../../../ui/card';
import { AlertContainer } from '../../../ui/alert';
import { Save, X, Plus, Trash2} from 'lucide-react';
import { GET_MANAGEABLE_SERVERS, GET_UNIT_HIERARCHY, GET_UNIT, CREATE_UNIT, UPDATE_UNIT, GET_ELIGIBLE_PARENT_UNITS } from '../../../../graphql/queries';

const SUGGESTED_CATEGORIES = [
  'COMMAND',
  'AVIATION',
  'INFANTRY',
  'ARMOR',
  'SUPPORT'
];

const UnitForm = ({ serverId, officeCode, unitId = null, onComplete }) => {
    const [formData, setFormData] = useState({
        name: '',
        abbreviation: '',
        category: '',
        currentStatus: 'ACTIVE',
        designation: '',
        parentUnit: null,
        attachedServers: [],
        heraldry: {
            emblem: '',
            color: ''
        }
    });

    const { data: unitData, loading: unitLoading } = useQuery(GET_UNIT, {
        variables: { id: unitId },
        skip: !unitId,
        onCompleted: (data) => {
            if (data?.unit) {
                setFormData({
                    name: data.unit.name || '',
                    abbreviation: data.unit.abbreviation || '',
                    category: data.unit.category || '',
                    currentStatus: data.unit.currentStatus || 'ACTIVE',
                    designation: data.unit.designation || '',
                    parentUnit: data.unit.parentUnit?._id || null,
                    attachedServers: data.unit.attachedServers || [],
                    heraldry: {
                        emblem: data.unit.heraldry?.emblem || '',
                        color: data.unit.heraldry?.color || ''
                    }
                });
            }
        }
    });

    const [error, setError] = useState('');

    const { data: hierarchyData } = useQuery(GET_UNIT_HIERARCHY, {
        variables: { serverId },
        skip: !serverId
    });

    const { data: eligibleParentUnits, loading: parentUnitsLoading, error: parentUnitsError } = useQuery(GET_ELIGIBLE_PARENT_UNITS, {
        variables: unitId ? { serverId, unitId } : { serverId },
        skip: !serverId, // Skip the query if serverId is not available
    });
        

    const { data: serversData } = useQuery(GET_MANAGEABLE_SERVERS);

    const [createUnit] = useMutation(CREATE_UNIT, {
        onCompleted: onComplete,
        refetchQueries: [{ 
            query: GET_UNIT_HIERARCHY, 
            variables: { 
                serverId,
                officeCode,
            },
        },
        {
            query: GET_ELIGIBLE_PARENT_UNITS,
            variables: { serverId, unitId: unitId || null }
        }],
        onError: (err) => setError(err.message)
    });

    const [updateUnit] = useMutation(UPDATE_UNIT, {
        onCompleted: onComplete,
        refetchQueries: [{ 
        query: GET_UNIT_HIERARCHY, 
            variables: { 
                serverId,
                officeCode,
            } 
        }],
        onError: (err) => setError(err.message)
    });
    const handleAttachServer = (selectedServerId) => {
        if (!selectedServerId || formData.attachedServers.includes(selectedServerId)) return;
        setFormData({
            ...formData,
            attachedServers: [...formData.attachedServers, selectedServerId]
        });
    };

    const handleRemoveServer = (selectedServerId) => {
        setFormData({
            ...formData,
            attachedServers: formData.attachedServers.filter(id => id !== selectedServerId)
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!serverId || !officeCode) {
            setError('Server ID and Office Code are required');
            return;
        }
    
        const input = {
            attachedServers: formData.attachedServers,
            name: formData.name,
            abbreviation: formData.abbreviation,
            category: formData.category,
            currentStatus: formData.currentStatus,
            designation: formData.designation || null,
            parentUnit: formData.parentUnit || null,
            heraldry: {
                emblem: formData.heraldry?.emblem || null,
                color: formData.heraldry?.color || null
            }
        };
    
        try {
            if (unitId) {
                await updateUnit({ 
                    variables: { 
                        serverId: serverId,
                        officeCode: officeCode,
                        id: unitId,
                        input: input 
                    }
                });
            } else {
                await createUnit({ 
                    variables: { 
                        serverId: serverId,
                        officeCode: officeCode,
                        input: input 
                    }
                });
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const availableServers = serversData?.manageableServers?.filter(
        server => server.id !== serverId && !formData.attachedServers.includes(server.id)
      ) || [];

    return (
        <Card className="bg-neutral-900/50 border-neutral-800">
        <form onSubmit={handleSubmit} className="space-y-6 p-6">
        {error && (
            <AlertContainer variant="destructive">{error}
            </AlertContainer>
            )}

            <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2">
                <label className="block text-sm font-medium mb-1 text-neutral-200">
                Name<span className="text-cyan-500">*</span>
                </label>
                <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                required
                />
            </div>

            <div>
                <label className="block text-sm font-medium mb-1 text-neutral-200">Abbreviation</label>
                <input
                type="text"
                value={formData.abbreviation}
                onChange={(e) => setFormData({ ...formData, abbreviation: e.target.value })}
                className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                />
            </div>

            <div>
                <label className="block text-sm font-medium mb-1 text-neutral-200">
                Category<span className="text-cyan-500">*</span>
                </label>
                <div className="flex gap-2">
                <input
                    type="text"
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    className="flex-1 p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                    required
                />
                <select
                    value=""
                    onChange={(e) => e.target.value && setFormData({ ...formData, category: e.target.value })}
                    className="w-1/2 p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                >
                    <option value="">Suggestions</option>
                    {SUGGESTED_CATEGORIES.map(category => (
                    <option key={category} value={category}>{category}</option>
                    ))}
                </select>
                </div>
            </div>

            <div>
                <label className="block text-sm font-medium mb-1 text-neutral-200">
                Status<span className="text-cyan-500">*</span>
                </label>
                <select
                value={formData.currentStatus}
                onChange={(e) => setFormData({ ...formData, currentStatus: e.target.value })}
                className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                required
                >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
                </select>
            </div>

            <div className="col-span-2">
                <label className="block text-sm font-medium mb-1 text-neutral-200">Designation</label>
                <input
                type="text"
                value={formData.designation}
                onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
                className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                />
            </div>

            <div className="col-span-2">
                <label className="block text-sm font-medium mb-1 text-neutral-200">Parent Unit</label>
                <select
                    value={formData.parentUnit || ''}
                    onChange={(e) => setFormData({ ...formData, parentUnit: e.target.value || null })}
                    className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                    disabled={parentUnitsLoading || parentUnitsError} // Disable dropdown if loading or in error state
                >
                    <option value="">No Parent Unit</option>
                    {parentUnitsLoading && <option>Loading parent units...</option>}
                    {parentUnitsError && <option>Error loading parent units</option>}
                    {!parentUnitsLoading && eligibleParentUnits?.eligibleParentUnits.map((unit) => (
                        <option key={unit._id} value={unit._id}>
                            {unit.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>

        <div className="border-t border-neutral-700 pt-4">
                <h3 className="text-lg font-medium mb-4 text-neutral-200">Heraldry</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                    <label className="block text-sm font-medium mb-1 text-neutral-200">Emblem URL</label>
                    <input
                        type="url"
                        value={formData.heraldry.emblem}
                        onChange={(e) => setFormData({
                        ...formData,
                        heraldry: { ...formData.heraldry, emblem: e.target.value }
                        })}
                        className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                    />
                    </div>

                    <div className="col-span-2">
                    <label className="block text-sm font-medium mb-1 text-neutral-200">Color</label>
                    <input
                        type="text"
                        value={formData.heraldry.color}
                        onChange={(e) => setFormData({
                        ...formData,
                        heraldry: { ...formData.heraldry, color: e.target.value }
                        })}
                        placeholder="#RRGGBB"
                        className="w-full p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                    />
                    </div>
                </div>
            </div>

            <div className="border-t border-neutral-700 pt-4">
                <h3 className="text-lg font-medium mb-4 text-neutral-200">Attached Servers</h3>
                <div className="space-y-4">
                    <div className="flex gap-2">
                    <select
                        className="flex-1 p-2 bg-neutral-800 rounded border border-neutral-700 text-neutral-200"
                        onChange={(e) => handleAttachServer(e.target.value)}
                        value=""
                    >
                        <option value="">Select a server to attach...</option>
                        {availableServers.map(server => (
                        <option key={server.id} value={server.id}>
                            {server.name}
                        </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        className="p-2 bg-cyan-900/20 text-cyan-100 rounded hover:bg-cyan-800/30 transition-colors border border-cyan-900/50"
                        onClick={() => handleAttachServer(document.querySelector('select').value)}
                    >
                        <Plus className="w-4 h-4" />
                    </button>
                    </div>
                    
                    <div className="space-y-2">
                    {formData.attachedServers.map(attachedServerId => {
                        const server = serversData?.manageableServers?.find(s => s.id === attachedServerId);
                        return server ? (
                        <div key={server.id} className="flex items-center justify-between p-2 bg-neutral-800 rounded">
                            <span className="text-neutral-200">{server.name}</span>
                            <button
                            type="button"
                            onClick={() => handleRemoveServer(server.id)}
                            className="p-1 text-red-400 hover:text-red-300 transition-colors"
                            >
                            <Trash2 className="w-4 h-4" />
                            </button>
                        </div>
                        ) : null;
                    })}
                    </div>
                </div>
            </div>

            <div className="flex justify-end gap-2 pt-4">
            <button
                type="button"
                onClick={onComplete}
                className="px-4 py-2 bg-neutral-800 text-neutral-200 rounded hover:bg-neutral-700 transition-colors flex items-center"
            >
                <X className="w-4 h-4 mr-2" />
                Cancel
            </button>
            <button
                type="submit"
                className="px-4 py-2 bg-cyan-900/20 text-cyan-100 rounded hover:bg-cyan-800/30 transition-colors flex items-center border border-cyan-900/50"
            >
                <Save className="w-4 h-4 mr-2" />
                {unitId ? 'Update Unit' : 'Create Unit'}
            </button>
            </div>
        </form>
        </Card>
    );
};

export default UnitForm;