import { gql } from '@apollo/client';

// Fragment to share common user fields
const USER_FIELDS = gql`
  fragment UserFields on User {
    duid
    discord {
      username
      avatar
      connections {
        id
        name
        type
        verified
      }
      guilds {
        id
        name
        icon
        owner
        permissions
      }
      updatedAt
    }
    xbox {
      xuid
      gamertag
      modernGamertag
    }
    rsgsc {
      nickname
      id
      platforms
    }
    history {
      gamertags
      crews
    }
    autobiography
    community
    blacklist
    banished
    dischargedTil
    muted
  }
`;

export const GET_MANAGEABLE_SERVERS = gql`
  query GetManageableServers {
    manageableServers {
      id
      name
      icon
      memberCount
      permissions
      botPresent
    }
  }
`;

export const GET_STAFF_OFFICES = gql`
  query GetStaffOffices($serverId: String!) {
    staffOffices(serverId: $serverId) {
      code
      name
      description
      roles
      users
      features
      channels {
        actions
        logs
      }
    }
  }
`;

export const GET_STAFF_OFFICE_DETAILS = gql`
  query GetStaffOfficeDetails($serverId: String!, $officeCode: String!) {
    staffOfficeDetails(serverId: $serverId, officeCode: $officeCode) {
      code
      name
      description
      roles
      users
      features
      channels {
        actions
        logs
      }
    }
  }
`;

export const GET_OFFICE_ACTIVITY = gql`
  query GetOfficeActivity($serverId: String!, $officeCode: String!, $limit: Int) {
    getOfficeActivity(serverId: $serverId, officeCode: $officeCode, limit: $limit) {
      timestamp
      userId
      action
      details
      type
    }
  }
`;

export const GET_UNIT = gql`
  query GetUnit($id: String!) {
    unit(id: $id) {
      _id
      name
      abbreviation
      category
      currentStatus
      serverOwner
      heraldry {
        icon
        emblem
        color
      }
      personnelCount
      children {
        _id
        name
        abbreviation
        category
        currentStatus
        serverOwner
        heraldry {
          icon
          emblem
          color
        }
        personnelCount
        children {
          _id
          name
          abbreviation
          category
          currentStatus
          serverOwner
          heraldry {
            icon
            emblem
            color
          }
          personnelCount
        }
      }
    }
  }
`;

export const GET_ELIGIBLE_PARENT_UNITS = gql`
  query GetEligibleParentUnits($serverId: String!, $unitId: String) {
    eligibleParentUnits(serverId: $serverId, unitId: $unitId) {
      _id
      serverOwner
      attachedServers
      name
      abbreviation
      category
      currentStatus
      designation
      parentUnit
      heraldry {
        emblem
        icon
        color
      }
      children {
        _id
        name
      }
    }
  }
`;

export const GET_UNIT_HIERARCHY = gql`
  query GetUnitHierarchy($serverId: String!) {
    unitHierarchy(serverId: $serverId) {
      _id
      name
      abbreviation
      category
      currentStatus
      serverOwner
      personnelCount
      heraldry {
        icon
        emblem
        color
      }
      children {
        _id
        name
        abbreviation
        category
        currentStatus
        serverOwner
        personnelCount
        heraldry {
          icon
          emblem
          color
        }
        children {
          _id
          name
          abbreviation
          category
          currentStatus
          serverOwner
          personnelCount
          heraldry {
            icon
            emblem
            color
          }
        }
      }
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation CreateUnit($serverId: String!, $officeCode: String!, $input: UnitInput!) {
    createUnit(serverId: $serverId, officeCode: $officeCode, input: $input) {
      _id
      serverOwner
      attachedServers
      name
      abbreviation
      category
      currentStatus
      designation
      parentUnit
      heraldry {
        emblem
        icon
        color
      }
    }
  }
`;

export const UPDATE_UNIT = gql`
  mutation UpdateUnit($serverId: String!, $officeCode: String!, $id: String!, $input: UnitInput!) {
    updateUnit(serverId: $serverId, officeCode: $officeCode, id: $id, input: $input) {
      _id
      serverOwner
      attachedServers
      name
      abbreviation
      category
      currentStatus
      designation
      parentUnit
      heraldry {
        emblem
        icon
        color
      }
    }
  }
`;

export const DELETE_UNIT = gql`
  mutation DeleteUnit($serverId: String!, $officeCode: String!, $id: String!) {
    deleteUnit(serverId: $serverId, officeCode: $officeCode, id: $id) {
      _id
    }
  }
`;

// Add staff system to server details query
export const GET_SERVER_DETAILS = gql`
  query GetServerDetails($serverId: String!) {
    serverDetails(serverId: $serverId) {
      id
      name
      icon
      banner
      memberCount
      features
      preferredLocale
      linkedCrew {
        name
        tag
        cache {
          icon
          motto
          memberCount
        }
        milnet {
          sato
          nomenclature
          description
          community
        }
      }
      channels {
        id
        name
        type
        position
        parent_id
      }
      roles {
        id
        name
        color
        position
        permissions
        managed
      }
      modules {
        welcome {
          enabled
          thumbnail
          message
          channel
        }
        feed {
          enabled
          channel
        }
        staffSystem {
          enabled
          commandRole
          staffRole
        }
      }
    }
  }
`;

export const GET_SERVER_USER_DETAILS = gql`
  query GetServerUserDetails($serverId: String!) {
    serverUserDetails(serverId: $serverId) {
      id
      nickname
      avatar
      joinedAt
      roles {
        id
        name
        color
        position
      }
      permissions
    }
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const SEARCH_BY_ALLPLATFORMS = gql`
  query SearchByAllPlatforms($term: String!) {
    searchByAllPlatforms(term: $term) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const SEARCH_BY_GAMERTAG = gql`
  query SearchByGamertag($xbox: String!) {
    searchByGamertag(xbox: $xbox) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const SEARCH_BY_DISCORD = gql`
  query SearchByDiscord($discord: String!) {
    searchByDiscord(discord: $discord) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const SEARCH_BY_SOCIALCLUB = gql`
  query SearchBySocialclub($socialclub: String!) {
    searchBySocialclub(socialclub: $socialclub) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

// Mutations
export const UPDATE_AUTOBIOGRAPHY = gql`
  mutation UpdateAutobiography($text: String!) {
    updateAutobiography(text: $text) {
      duid
      autobiography
    }
  }
`;

export const UPDATE_COMMUNITY = gql`
  mutation UpdateCommunity($community: String!) {
    updateCommunity(community: $community) {
      duid
      community
    }
  }
`;

export const REFRESH_DISCORD_DATA = gql`
  mutation RefreshDiscordData {
    refreshDiscordData {
      discord {
        username
        avatar
        guilds {
          id
          name
          icon
        }
        connections {
          id
          name
          type
          verified
        }
      }
    }
  }
`;