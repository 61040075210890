// auth.js
export const setAuthToken = (token) => {
    if (token) {
        try {
            localStorage.setItem('auth_token', token);
            sessionStorage.setItem('auth_token', token); // Backup in sessionStorage
            console.log('Token stored successfully');
        } catch (error) {
            console.error('Error storing token:', error);
            // Try sessionStorage as fallback
            try {
                sessionStorage.setItem('auth_token', token);
            } catch (e) {
                console.error('Failed to store token in sessionStorage:', e);
            }
        }
    } else {
        localStorage.removeItem('auth_token');
        sessionStorage.removeItem('auth_token');
        console.log('Token removed');
    }
};

export const getAuthToken = () => {
    try {
        // Try localStorage first, then sessionStorage
        const token = localStorage.getItem('auth_token') || 
                     sessionStorage.getItem('auth_token');
        console.log('Retrieved token:', token ? 'exists' : 'not found');
        return token;
    } catch (error) {
        console.error('Error getting token:', error);
        return null;
    }
};