// ExchangeToken.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from './auth';

const AuthCallback = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('Initializing login...');

    useEffect(() => {
        const handleAuth = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const token = urlParams.get('token');
                
                if (!token) {
                    console.error('No token found in URL parameters');
                    setStatus('Login failed: No token found');
                    setTimeout(() => navigate('/'), 2000);
                    return;
                }

                console.log('Received token:', token);
                
                // Store the JWT token immediately
                setAuthToken(token);
                
                // Verify the token works by making an auth check
                const response = await fetch('https://milstar.glitch.me/auth/status', {
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                
                const data = await response.json();
                console.log('Auth status response:', data);
                
                if (data.authenticated) {
                    setStatus('Login successful! Redirecting...');
                    setTimeout(() => navigate('/dashboard'), 1000);
                } else {
                    console.error('Token verification failed');
                    setStatus('Login failed: Invalid token');
                    setAuthToken(null);
                    setTimeout(() => navigate('/'), 2000);
                }
            } catch (error) {
                console.error('Auth error:', error);
                setStatus('Login failed: Network error');
                setAuthToken(null);
                setTimeout(() => navigate('/'), 2000);
            }
        };

        handleAuth();
    }, [navigate]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            padding: '20px',
            textAlign: 'center'
        }}>
            <div>
                <h2>MILSTAR Authentication</h2>
                <p>{status}</p>
            </div>
        </div>
    );
};

export default AuthCallback;