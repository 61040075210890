import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SERVER_DETAILS } from '../../graphql/queries';
import { Flag } from 'lucide-react';
import ServerUserInfo from './ServerUserInfo';
import StaffSystem from '../management/StaffSystem';

const ServerManage = () => {
  const { serverId } = useParams();
  const navigate = useNavigate();
  
  const { loading, error, data } = useQuery(GET_SERVER_DETAILS, {
    variables: { serverId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-8">
        Error: {error.message}
      </div>
    );
  }

  const server = data?.serverDetails;
  const isStaffEnabled = server?.modules?.staffSystem?.enabled;
  
  // Construct banner URL if banner exists
  const bannerUrl = server?.banner 
    ? `https://cdn.discordapp.com/banners/${server.id}/${server.banner}.png?size=1024` 
    : null;

  const renderModuleStatus = (moduleName, module, description) => (
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-neutral-100 font-medium">{moduleName}</h3>
        <p className="text-sm text-neutral-400">{description}</p>
      </div>
      <div className={`px-2 py-1 rounded ${
        module?.enabled 
          ? 'bg-green-500/20 text-green-300' 
          : 'bg-neutral-600/20 text-neutral-400'
      }`}>
        {module?.enabled ? 'Enabled' : 'Disabled'}
      </div>
    </div>
  );

  return (
    <div className="p-8">
      {/* Back Button */}
      <div className="flex items-center gap-4 mb-8">
        <button
          onClick={() => navigate('/dashboard')}
          className="text-neutral-400 hover:text-neutral-100 transition-colors"
        >
          ← Back to Servers
        </button>
      </div>

      <div className="bg-gradient-to-br from-neutral-800/90 to-neutral-900/90 rounded-lg border border-cyan-500/50 overflow-hidden">
        {/* Banner Section */}
        <div 
          className="relative p-6 min-h-[200px] flex items-center justify-between"
          style={bannerUrl ? {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${bannerUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          } : {}}
        >
          {/* Server Info */}
          <div className="flex items-center gap-4">
            {server?.icon ? (
              <img
                src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`}
                alt=""
                className="w-16 h-16 rounded-full border-2 border-neutral-700"
              />
            ) : (
              <div className="w-16 h-16 rounded-full bg-neutral-700 flex items-center justify-center border-2 border-gray-600">
                <span className="text-2xl text-neutral-400">
                  {server?.name.charAt(0)}
                </span>
              </div>
            )}
            <div className="z-10">
              <h1 className="text-2xl font-bold text-neutral-100">{server?.name}</h1>
              <p className="text-neutral-300">{server?.memberCount.toLocaleString()} members</p>
            </div>
          </div>

          {/* Crew Info */}
          {server?.linkedCrew && (
            <div className="flex items-center gap-4 bg-neutral-800/50 rounded-lg px-4 py-3 border border-cyan-900/20">
              <div className="flex items-center gap-3">
                {server.linkedCrew.cache?.icon ? (
                  <img 
                    src={server.linkedCrew.cache.icon} 
                    alt={`${server.linkedCrew.name} icon`}
                    className="w-12 h-12 rounded"
                  />
                ) : (
                  <div className="w-12 h-12 rounded bg-neutral-700/50 flex items-center justify-center">
                    <Flag className="w-6 h-6 text-cyan-300" />
                  </div>
                )}
                <div>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-neutral-400">Linked Crew</span>
                    {server.linkedCrew.milnet?.sato && (
                      <span className="px-2 py-0.5 bg-cyan-500/20 rounded-full text-xs text-cyan-300">
                        SATO
                      </span>
                    )}
                  </div>
                  <h3 className="text-lg font-semibold text-neutral-100">
                    {server.linkedCrew.name}
                  </h3>
                  {server.linkedCrew.cache?.motto && (
                    <p className="text-sm text-neutral-300 italic">
                      "{server.linkedCrew.cache.motto}"
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Content Section */}
        <div className="p-6 space-y-8">
          {/* Staff System - Only render if enabled */}
          {isStaffEnabled && (
            <div>
              <h2 className="text-xl font-semibold text-neutral-100 mb-4">Staff Offices</h2>
              <StaffSystem serverId={serverId} />
            </div>
          )}

          {/* Regular Server Management Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Server User Info */}
            <ServerUserInfo serverId={serverId} />

            {/* Server Features */}
            <div className="bg-neutral-800/50 rounded-lg p-4">
              <h2 className="text-lg font-semibold text-neutral-100 mb-3">Server Features</h2>
              <div className="flex flex-wrap gap-2">
                {server?.features.map(feature => (
                  <span key={feature} className="px-2 py-1 bg-neutral-700 rounded-full text-sm text-neutral-300">
                    {feature}
                  </span>
                ))}
              </div>
            </div>

            {/* Server Info */}
            <div className="bg-neutral-800/50 rounded-lg p-4">
              <h2 className="text-lg font-semibold text-neutral-100 mb-3">Server Information</h2>
              <div className="space-y-2">
                <p className="text-neutral-300">
                  <span className="text-neutral-400">Locale:</span> {server?.preferredLocale}
                </p>
                <p className="text-neutral-300">
                  <span className="text-neutral-400">Channels:</span> {server?.channels.length}
                </p>
                <p className="text-neutral-300">
                  <span className="text-neutral-400">Roles:</span> {server?.roles.length}
                </p>
              </div>
            </div>

            {/* Server Modules */}
            <div className="bg-neutral-800/50 rounded-lg p-4">
              <h2 className="text-lg font-semibold text-neutral-100 mb-3">Server Modules</h2>
              <div className="space-y-4">
                {renderModuleStatus(
                  'Welcome Module',
                  server?.modules?.welcome,
                  'Customize welcome messages for new members'
                )}
                {renderModuleStatus(
                  'Feed Module',
                  server?.modules?.feed,
                  'Manage server activity feed'
                )}
                {renderModuleStatus(
                  'Staff System',
                  server?.modules?.staffSystem,
                  'Manage staff offices and permissions'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerManage;