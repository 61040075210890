import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Xbox = () => {
  const { xuid } = useParams();

  useEffect(() => {
    window.location.href = `xbox://launch?type=profile&xuid=${xuid}`;
  }, [xuid]); // This ensures the redirect happens whenever the xuid changes

  return (
    <div className='Xbox'>
      <p>If you are not redirected, <a href={`xbox://launch?type=profile&xuid=${xuid}`}>click here</a>.</p>
    </div>
  );
}

export default Xbox;