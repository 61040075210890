import React from 'react';
import { useQuery } from '@apollo/client';
import { SEARCH_BY_ALLPLATFORMS, SEARCH_BY_GAMERTAG, SEARCH_BY_DISCORD, SEARCH_BY_SOCIALCLUB } from '../../graphql/queries';
import { discordIcon, xboxIcon, rockstarIcon } from '../brand/imports';
import { TextHeading } from '../../styled/styles';

const icons = {
  discord: discordIcon,
  xbox: xboxIcon,
  socialClub: rockstarIcon
}

// Common styles for label containers
const labelContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '24px 100px 1fr', // Icon + Label + Value
  gap: '0.5rem',
  alignItems: 'center',
  marginBottom: '0.5rem'
};

const labelStyle = {
  color: 'rgba(6, 182, 212, 0.8)',
  fontFamily: 'var(--font-text)',
  letterSpacing: '0.1em',
  textAlign: 'right'
};

const valueStyle = {
  color: 'white',
  fontFamily: 'var(--font-text)',
  letterSpacing: '0.1em'
};

const GetUsers = ({ search, platform }) => {
  const queryConfig = {
    'All Platforms': {
      query: SEARCH_BY_ALLPLATFORMS,
      resultField: 'searchByAllPlatforms',
      variable: 'term'
    },
    'Xbox': {
      query: SEARCH_BY_GAMERTAG,
      resultField: 'searchByGamertag',
      variable: 'xbox'
    },
    'Discord': {
      query: SEARCH_BY_DISCORD,
      resultField: 'searchByDiscord',
      variable: 'discord'
    },
    'Social Club': {
      query: SEARCH_BY_SOCIALCLUB,
      resultField: 'searchBySocialClub',
      variable: 'socialclub'
    }
  };

  const config = queryConfig[platform];
  
  const { loading, error, data } = useQuery(config?.query, {
    variables: { [config?.variable]: search },
    skip: !search?.trim() || !config,
    fetchPolicy: 'network-only',
    onError: (error) => {
      // Handle unauthorized error
      if (error.message.includes('Unauthorized') || error.message.includes('not authenticated')) {
        window.location.reload(); // Force reload to show login prompt
      }
    }
  });

  if (!platform) return <p className="text-lg text-slate-200">Select a platform to search</p>;
  if (!search?.trim()) return <p className="text-lg text-slate-200">Enter a gamertag to search</p>;
  if (loading) return <p className="text-lg text-slate-200">Loading...</p>;
  if (error) {
    if (error.message.includes('Unauthorized') || error.message.includes('not authenticated')) {
      return <p className="text-lg text-slate-200">Please sign in to search</p>;
    }
    return <p className="text-lg text-red-500">Error: {error.message}</p>;
  }
  if (!data?.[config.resultField]?.length) return <p className="text-lg text-slate-200">No users found</p>;

  return (
    <div style={{ 
      display: 'grid', 
      gridTemplateColumns: '1fr', 
      gap: '1rem', 
      padding: '1.5rem'
    }}>
      {data[config.resultField].map(user => (
        <TextHeading key={user.duid} style={{
          background: 'linear-gradient(to bottom right, rgba(42, 42, 43, 0.9), rgba(50, 52, 53, 0.9))',
          padding: '1rem',
          borderRadius: '0.5rem',
          border: '1px solid rgba(6, 182, 212, 0.5)',
          position: 'relative',
          overflow: 'hidden'
        }}>
          {user.xbox?.gamertag && (
            <div style={labelContainerStyle}>
              {icons?.xbox && <img src={icons.xbox} alt="" style={{ width: '24px', height: '24px' }} />}
              <span style={labelStyle}>Gamertag:</span>
              <span style={valueStyle}>{user.xbox.gamertag}</span>
            </div>
          )}
          {user.discord?.username && (
            <div style={labelContainerStyle}>
              {icons?.discord && <img src={icons.discord} alt="" style={{ width: '24px', height: '24px' }} />}
              <span style={labelStyle}>Discord:</span>
              <span style={valueStyle}>{user.discord.username}</span>
            </div>
          )}
          {user.rsgsc?.nickname && (
            <div style={labelContainerStyle}>
              {icons?.socialClub && <img src={icons.socialClub} alt="" style={{ width: '24px', height: '24px' }} />}
              <span style={labelStyle}>Social Club:</span>
              <span style={valueStyle}>{user.rsgsc.nickname}</span>
            </div>
          )}
        </TextHeading>
      ))}
    </div>
  );
};

export default GetUsers;