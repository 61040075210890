import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_SERVER_USER_DETAILS } from '../../graphql/queries';

const ServerUserInfo = ({ serverId }) => {
  const { loading, error, data } = useQuery(GET_SERVER_USER_DETAILS, {
    variables: { serverId },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <div className="bg-neutral-800/50 rounded-lg p-4">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-neutral-700 h-12 w-12"></div>
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-neutral-700 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-neutral-700 rounded"></div>
              <div className="h-4 bg-neutral-700 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  const { nickname, avatar, joinedAt, roles } = data.serverUserDetails;
  const highestRole = roles[0]; // Roles are sorted by position in resolver

  const avatarUrl = avatar 
    ? `https://cdn.discordapp.com/guilds/${serverId}/users/${data.serverUserDetails.id}/avatars/${avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/0.png`;

  return (
    <div className="bg-neutral-800/50 rounded-lg p-4">
      <h2 className="text-lg font-semibold text-neutral-100 mb-3">Your Server Profile</h2>
      <div className="flex items-start space-x-4">
        <img
          src={avatarUrl}
          alt=""
          className="w-12 h-12 rounded-full"
        />
        <div className="flex-1">
          <div className="flex items-center space-x-2">
            <span 
              className="font-medium text-lg"
              style={{ color: highestRole?.color || 'white' }}
            >
              {nickname}
            </span>
          </div>
          <div className="mt-2 space-y-1">
            {highestRole && (
              <div className="flex items-center space-x-2">
                <span className="text-neutral-400">Highest Role:</span>
                <span 
                  className="px-2 py-0.5 rounded-full text-sm"
                  style={{ 
                    backgroundColor: `${highestRole.color}1A`,
                    color: highestRole.color 
                  }}
                >
                  {highestRole.name}
                </span>
              </div>
            )}
            <div className="text-neutral-400 text-sm">
              Joined {new Date(joinedAt).toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerUserInfo;