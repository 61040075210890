import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardButton = () => {
  const [authStatus, setAuthStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('https://milstar.glitch.me/auth/status', {
          credentials: 'include'
        });
        const status = await response.json();
        setAuthStatus(status);
      } catch (error) {
        setAuthStatus({ authenticated: false });
      }
    };

    checkAuth();
  }, []);

  if (!authStatus?.authenticated) {
    return null;
  }

  return (
    <button 
      onClick={() => navigate('/dashboard')}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
        backgroundColor: '#5865F2',
        color: 'white',
        padding: '0.625rem 1.5rem',
        borderRadius: '0.375rem',
        fontFamily: 'var(--font-text)',
        fontWeight: 'bold',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        WebkitTapHighlightColor: 'transparent'
      }}
      onMouseEnter={(e) => e.target.style.backgroundColor = '#4752C4'}
      onMouseLeave={(e) => e.target.style.backgroundColor = '#5865F2'}
    >
      <svg 
        style={{
          width: '1.5rem',
          height: '1.5rem',
          fill: 'currentColor'
        }} 
        viewBox="0 0 24 24"
      >
        <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm9-8.586 6 6V15l.001 5H6v-9.586l6-6z"/>
        <path d="M12 18c3.703 0 4.901-3.539 4.95-3.689l-1.9-.621c-.008.023-.781 2.31-3.05 2.31-2.238 0-3.02-2.221-3.051-2.316l-1.899.627C7.099 14.461 8.297 18 12 18z"/>
      </svg>
      <span style={{ display: 'inline-block' }}>Dashboard</span>
    </button>
  );
};

export default DashboardButton;