import React from 'react';
import { useQuery } from '@apollo/client';
import { User, Search } from 'lucide-react';
import { Card } from '../../../ui/card';
import { gql } from '@apollo/client';

const GET_UNASSIGNED_PERSONNEL = gql`
    query GetUnassignedPersonnel($serverId: String!) {
      personnelRecordsThisServer(serverId: $serverId) {
        id
        username
        nickname
        avatar 
        record {
          id
          rank
          position
          branch
          unit
        }
      }
    }
  `;

const UnassignedPersonnel = ({ serverId, onSelectMember }) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const { loading, data } = useQuery(GET_UNASSIGNED_PERSONNEL, {
    variables: { serverId },
    fetchPolicy: 'network-only'
  });

  const unassignedPersonnel = React.useMemo(() => {
    if (!data?.personnelRecordsThisServer) return [];
    return data.personnelRecordsThisServer.filter(member => !member.record.unit);
  }, [data]);
  
  const filteredPersonnel = React.useMemo(() => {
    if (!searchQuery) return unassignedPersonnel;
    const lowercaseQuery = searchQuery.toLowerCase();
    return unassignedPersonnel.filter(member => 
      member.record.rank?.toLowerCase().includes(lowercaseQuery) ||
      member.record.position?.toLowerCase().includes(lowercaseQuery) ||
      member.record.branch?.toLowerCase().includes(lowercaseQuery)
    );
  }, [unassignedPersonnel, searchQuery]);

  if (loading) {
    return (
      <Card className="p-4">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-neutral-800 rounded w-1/2"></div>
          <div className="h-12 bg-neutral-800 rounded"></div>
          <div className="space-y-2">
            {[1, 2, 3].map(i => (
              <div key={i} className="h-16 bg-neutral-800 rounded"></div>
            ))}
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card className="p-4 bg-neutral-800/50 border-neutral-700">
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-neutral-200">
            Unassigned Personnel ({unassignedPersonnel.length})
          </h3>
        </div>

        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 w-4 h-4" />
          <input
            type="text"
            placeholder="Filter personnel..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-neutral-900/50 border border-neutral-700 rounded-lg
                     focus:outline-none focus:border-cyan-500/50"
          />
        </div>

        <div className="space-y-2 max-h-96 overflow-y-auto">
          {filteredPersonnel.length > 0 ? (
            filteredPersonnel.map(member => (
                <button
                  key={member.id}
                  onClick={() => onSelectMember({ 
                    id: member.id,
                    username: member.username,
                    nickname: member.nickname,
                    avatar: member.avatar
                  })}
                  className="w-full p-3 bg-neutral-900/50 border border-neutral-700 rounded-lg 
                             hover:bg-neutral-800 transition-colors text-left group"
                >
                  <div className="flex items-center space-x-3">
                    {member.avatar ? (
                      <img 
                        src={`https://cdn.discordapp.com/avatars/${member.id}/${member.avatar}.png`}
                        alt=""
                        className="w-5 h-5 rounded-full"
                      />
                    ) : (
                      <User className="w-5 h-5 text-neutral-400 group-hover:text-cyan-400" />
                    )}
                    <div>
                      <div className="font-medium text-neutral-200">
                        {member.record.rank || 'No Rank'} - {member.record.position || 'No Position'}
                      </div>
                      <div className="text-sm text-neutral-400">
                        {member.username} {member.nickname && `(${member.nickname})`}
                        <span className="mx-1">•</span>
                        {member.record.branch || 'No Branch'}
                      </div>
                    </div>
                  </div>
                </button>
              ))
          ) : (
            <div className="text-center py-8 text-neutral-400">
              {searchQuery ? 'No matching personnel found' : 'No unassigned personnel'}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default UnassignedPersonnel;