import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_STAFF_OFFICE_DETAILS } from '../../graphql/queries';
import { Shield, ArrowLeft, Clock, Users, Activity, Database } from 'lucide-react';
import { Card } from '../ui/card';
import ActivityFeed from './ActivityFeed';
import UnitManagement from './features/units/UnitManagement';


const StaffOfficeView = () => {
  const { serverId, officeCode } = useParams();
  const navigate = useNavigate();
  
  const { loading, error, data } = useQuery(GET_STAFF_OFFICE_DETAILS, {
    variables: { serverId, officeCode: officeCode.toUpperCase() },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-neutral-900">
        <div className="w-8 h-8 border-t-2 border-cyan-500 rounded-full animate-spin" />
      </div>
    );
  }

  if (error || !data?.staffOfficeDetails) {
    return (
      <div className="p-8 bg-neutral-900">
        <Card className="p-6 bg-neutral-800/50 border-red-500/20">
          <h2 className="text-lg font-bold text-red-400 font-header">Error</h2>
          <p className="text-neutral-400 mt-2 font-text">
            {error ? error.message : 'This office does not exist or you don\'t have access to it.'}
          </p>
        </Card>
      </div>
    );
  }

  const office = data.staffOfficeDetails;
  const showUnitManagement = office.features.includes('unit-management');

  // Calculate stats
  const totalMembers = (office.users?.length || 0) + (office.roles?.length || 0);
  const activeFeatures = office.features?.length || 0;

  return (
    <div className="min-h-screen bg-neutral-900 p-6 font-text">
      {/* Header Section */}
      <div className="mb-8">
        <button
          onClick={() => navigate(`/dashboard/${serverId}`)}
          className="flex items-center text-neutral-400 hover:text-cyan-300 transition-colors mb-6 font-header"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Server
        </button>

        <div className="bg-gradient-to-br from-neutral-800/90 to-neutral-900/90 rounded-lg border border-cyan-500/50 overflow-hidden">
          <div className="relative p-6 min-h-[200px] flex items-center">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-neutral-800/50 rounded-lg border border-cyan-900/20">
                <Shield className="w-8 h-8 text-cyan-300" />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-neutral-100 font-header">
                  {office.code} - {office.name}
                </h1>
                <p className="text-neutral-400 mt-1">{office.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Column - Statistics */}
        <div className="lg:col-span-2 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Stats Cards */}
            <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-4 transform -skew-x-1">
              <div className="flex items-center gap-3">
                <Users className="w-5 h-5 text-cyan-300" />
                <h3 className="text-neutral-300 font-header">Total Members</h3>
              </div>
              <p className="text-2xl font-bold text-neutral-100 mt-2">{totalMembers}</p>
            </div>

            <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-4 transform -skew-x-1">
              <div className="flex items-center gap-3">
                <Activity className="w-5 h-5 text-cyan-300" />
                <h3 className="text-neutral-300 font-header">Active Features</h3>
              </div>
              <p className="text-2xl font-bold text-neutral-100 mt-2">{activeFeatures}</p>
            </div>

            <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-4 transform -skew-x-1">
              <div className="flex items-center gap-3">
                <Clock className="w-5 h-5 text-cyan-300" />
                <h3 className="text-neutral-300 font-header">Last Activity</h3>
              </div>
              <p className="text-sm text-neutral-400 mt-2">No recent activity</p>
            </div>
          </div>

          {/* Activity Feed */}
          <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-6 transform skew-x-1">
            <h2 className="text-xl font-bold text-neutral-100 mb-4 font-header">Recent Activity</h2>
            <ActivityFeed serverId={serverId} officeCode={officeCode} />
          </div>
          {/* Unit Management Section */}
          {/* {showUnitManagement && (
            <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-6">
              <div className="flex items-center gap-3 mb-4">
                <Database className="w-5 h-5 text-cyan-300" />
                <h2 className="text-xl font-bold text-neutral-100">Unit Management</h2>
              </div>
              <UnitManagement serverId={serverId} />
            </div>
          )} */}
        </div>

        {/* Right Column - Actions & Info */}
        <div className="space-y-6">
            {/* Quick Actions */}
            <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-6 transform -skew-x-1">
            <h2 className="text-xl font-bold text-neutral-100 mb-4 font-header">Quick Actions</h2>
            <div className="space-y-3">
                {office.features.includes('personnel-files') && (
                <button
                    onClick={() => navigate(`personnel`)}
                    className="w-full px-4 py-2 bg-cyan-900/20 hover:bg-cyan-800/30 
                            text-cyan-100 rounded transition-colors border border-cyan-900/30
                            transform hover:scale-[1.02] font-header"
                >
                    Personnel Files
                </button>
                )}
                {office.features.includes('unit-management') && (
                <button
                  onClick={() => navigate(`/dashboard/${serverId}/staff/${officeCode}/units`)}
                  className="w-full px-4 py-2 bg-cyan-900/20 hover:bg-cyan-800/30 
                            text-cyan-100 rounded transition-colors border border-cyan-900/30
                            transform hover:scale-[1.02] font-header"
                  >
                    <Database className="w-4 h-4 mr-2 inline" />
                    Unit Management
                  </button>
                )}
                {office.features.map((feature) => feature !== 'personnel-files' && (
                <button
                    key={feature}
                    className="w-full px-4 py-2 bg-cyan-900/20 hover:bg-cyan-800/30 
                            text-cyan-100 rounded transition-colors border border-cyan-900/30
                            transform hover:scale-[1.02] font-header"
                >
                    Access {feature}
                </button>
                ))}
            </div>
            </div>

          {/* Office Information */}
          <div className="bg-neutral-800/30 border border-neutral-700/50 rounded-lg p-6 transform -skew-x-1">
            <h2 className="text-xl font-bold text-neutral-100 mb-4 font-header">Office Information</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-sm font-medium text-neutral-400 mb-1">Actions Channel</h3>
                <p className="text-neutral-100">#{office.channels.actions || 'Not Set'}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-neutral-400 mb-1">Logs Channel</h3>
                <p className="text-neutral-100">#{office.channels.logs || 'Not Set'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffOfficeView;