import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Search, Save, Edit2, UserPlus, Trash2, AlertCircle, X, Building2 } from 'lucide-react';
import { format } from 'date-fns';
import _ from 'lodash';
import { gql } from '@apollo/client';
import UnassignedPersonnel from './UnassignedPersonnel';


const GET_ASSIGNABLE_UNITS = gql`
  query GetAssignableUnits($serverId: String!) {
    getAssignableUnits(serverId: $serverId) {
      _id
      name
      abbreviation
      category
      currentStatus
      parentUnit
    }
  }
`;
// GraphQL queries/mutations
const SEARCH_SERVER_MEMBERS = gql`
  query SearchServerMembers($serverId: String!, $query: String!) {
    searchServerMembers(serverId: $serverId, query: $query) {
      id
      username
      discriminator
      avatar
      nickname
    }
  }
`;

const GET_PERSONNEL_RECORD = gql`
  query GetPersonnelRecord($userId: String!) {
    personnelRecord(userId: $userId) {
      id
      unit
      tz
      paygrade
      rank
      doe
      dor
      status
      flags
      notes
      platforms
      command
      branch
      position
      callsign
    }
  }
`;

const CREATE_PERSONNEL_RECORD = gql`
  mutation CreatePersonnelRecord(
    $userId: String!
    $record: PersonnelRecordInput!
    $serverId: String!
    $officeCode: String!
  ) {
    createPersonnelRecord(
      userId: $userId
      record: $record
      serverId: $serverId
      officeCode: $officeCode
    ) {
      id
      unit
      rank
      position
      branch
    }
  }
`;

const UPDATE_PERSONNEL_RECORD = gql`
  mutation UpdatePersonnelRecord(
    $userId: String!
    $record: PersonnelRecordInput!
    $serverId: String!
    $officeCode: String!
  ) {
    updatePersonnelRecord(
      userId: $userId
      record: $record
      serverId: $serverId
      officeCode: $officeCode
    ) {
      id
      unit
      tz
      paygrade
      rank
      doe
      dor
      status
      flags
      notes
      platforms
      command
      branch
      position
      callsign
    }
  }
`;

const DELETE_PERSONNEL_RECORD = gql`
  mutation DeletePersonnelRecord(
    $userId: String!
    $serverId: String!
    $officeCode: String!
  ) {
    deletePersonnelRecord(
      userId: $userId
      serverId: $serverId
      officeCode: $officeCode
    ) {
      id
    }
  }
`;

const PersonnelFiles = ({ serverId, officeCode }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});

    const { data: unitData } = useQuery(GET_ASSIGNABLE_UNITS, {
      variables: { serverId },
      skip: !serverId
    });

    // Search members query with skip option
    const { loading: searchLoading, data: searchData, refetch } = useQuery(SEARCH_SERVER_MEMBERS, {
      variables: { serverId, query: searchQuery },
      skip: !isSearching || !searchQuery || searchQuery.length < 2,
      fetchPolicy: 'network-only'
    });

    // Get personnel record query
    const { loading: recordLoading, data: recordData } = useQuery(GET_PERSONNEL_RECORD, {
      variables: { userId: selectedUser?.id },
      skip: !selectedUser
    });
  
    // Update mutation with serverId and officeCode
    const [updateRecord] = useMutation(UPDATE_PERSONNEL_RECORD, {
      onError: (error) => {
        console.error('Error updating record:', error);
      }
    });
  
    // Create mutation with serverId and officeCode
    const [createRecord] = useMutation(CREATE_PERSONNEL_RECORD, {
      onError: (error) => {
        console.error('Error creating record:', error);
      }
    });

    const getUnitName = (unitId) => {
      const unit = unitData?.getAssignableUnits?.find(u => u._id === unitId);
      return unit ? `${unit.abbreviation ? `[${unit.abbreviation}] ` : ''}${unit.name}` : '-';
    };

    // Debounced search function
    const debouncedSearch = useCallback(
      _.debounce((query) => {
        if (query.length >= 2) {
          setIsSearching(true);
        }
      }, 500),
      []
    );

    const handleSearchInput = (e) => {
      const value = e.target.value;
      setSearchQuery(value);
      setIsSearching(false);
      debouncedSearch(value);
    };
  
    const handleSearchClick = () => {
      if (searchQuery.length >= 2) {
        setIsSearching(true);
        refetch();
      }
    };
  
    const handleSelectMember = (member) => {
      setSelectedUser(member);
      setSearchQuery('');
      setIsSearching(false);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
    };
  
    const handleSubmit = async () => {
      const { __typename, id, ...recordInput } = formData;
      try {
        const variables = {
          userId: selectedUser.id,
          record: recordInput,
          serverId,
          officeCode
        };
  
        if (recordData?.personnelRecord) {
          await updateRecord({ variables });
        } else {
          await createRecord({ variables });
        }
        setIsEditing(false);
      } catch (error) {
        console.error('Error saving record:', error);
      }
    };
  
    return (
      <div className="space-y-6">
        {/* Search Bar */}
        <div className="relative">
        <div className="flex items-center space-x-2">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 w-4 h-4" />
            <input
              type="text"
              placeholder="Search members..."
              value={searchQuery}
              onChange={handleSearchInput}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchClick();
                }
              }}
              className="w-full pl-10 pr-4 py-2 bg-neutral-800/50 border border-neutral-700 rounded-lg focus:outline-none focus:border-cyan-500/50"
            />
          </div>
          <button
            onClick={handleSearchClick}
            disabled={searchQuery.length < 2}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              searchQuery.length < 2
                ? 'bg-neutral-700/50 text-neutral-500 cursor-not-allowed'
                : 'border border-cyan-500/20 bg-cyan-500/10 hover:bg-cyan-500/20'
            }`}
          >
            <Search className="w-4 h-4" />
            <span>Search</span>
          </button>
          <button 
            className="px-4 py-2 flex items-center border border-cyan-500/20 bg-cyan-500/10 hover:bg-cyan-500/20 rounded-lg"
          >
            <UserPlus className="w-4 h-4 mr-2" />
            New Record
          </button>
        </div>
  
          {/* Search Results Dropdown */}
          {isSearching && searchQuery.length >= 2 && (
          <div className="absolute w-full mt-1 bg-neutral-800 border border-neutral-700 rounded-lg shadow-lg z-10 max-h-96 overflow-y-auto">
            {searchLoading ? (
              <div className="flex items-center justify-center p-4">
                <div className="w-6 h-6 border-2 border-cyan-500 border-t-transparent rounded-full animate-spin" />
              </div>
            ) : searchData?.searchServerMembers?.length > 0 ? (
              searchData.searchServerMembers.map((member) => (
                <button
                  key={member.id}
                  onClick={() => handleSelectMember(member)}
                  className="w-full px-4 py-2 text-left hover:bg-neutral-700 flex items-center space-x-3"
                >
                  <img
                    src={member.avatar ? `https://cdn.discordapp.com/avatars/${member.id}/${member.avatar}.png` : '/default-avatar.png'}
                    alt=""
                    className="w-8 h-8 rounded-full"
                  />
                  <span>{member.username}</span>
                  {member.nickname && member.nickname !== member.username && (
                    <span className="text-neutral-400">({member.nickname})</span>
                  )}
                </button>
              ))
            ) : (
              <div className="p-4 text-neutral-400 text-center">
                No members found
              </div>
            )}
          </div>
        )}
      </div>
      <UnassignedPersonnel 
        serverId={serverId} 
        onSelectMember={handleSelectMember} 
      />
  
        {/* Personnel Record Display */}
        {selectedUser && (
          <div className="bg-neutral-800/50 border border-neutral-700 rounded-lg">
            <div className="flex flex-row items-center justify-between p-4 border-b border-neutral-700">
              <div className="text-xl font-semibold flex items-center space-x-3">
                <img
                  src={selectedUser.avatar ? `https://cdn.discordapp.com/avatars/${selectedUser.id}/${selectedUser.avatar}.png` : '/default-avatar.png'}
                  alt=""
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-cyan-300">{selectedUser.username}</span>
              </div>
              <button
                onClick={() => {
                  if (isEditing) {
                    handleSubmit();
                  } else {
                    setIsEditing(true);
                    setFormData(recordData?.personnelRecord || {});
                  }
                }}
                className="px-4 py-2 flex items-center border border-cyan-500/20 bg-cyan-500/10 hover:bg-cyan-500/20 rounded-lg"
              >
                {isEditing ? (
                  <>
                    <Save className="w-4 h-4 mr-2" />
                    Save
                  </>
                ) : (
                  <>
                    <Edit2 className="w-4 h-4 mr-2" />
                    Edit
                  </>
                )}
              </button>
            </div>
  
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Basic Information */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-lg text-cyan-300">Basic Information</h3>
                  <div className="space-y-3">
                    {/* Unit Assignment Field */}
                    <div className="grid grid-cols-3 items-center">
                      <span className="text-neutral-300 font-newmedia tracking-[0.1em]">
                        <Building2 className="w-4 h-4 inline-block mr-2" />
                        Unit
                      </span>
                      {isEditing ? (
                        <select
                          name="unit"
                          value={formData.unit || ''}
                          onChange={handleInputChange}
                          className="col-span-2 px-3 py-1.5 bg-neutral-900/50 border border-gray-600 rounded-lg 
                                  focus:outline-none focus:border-cyan-500/50 text-white"
                        >
                          <option value="">No Unit Assigned</option>
                          {unitData?.getAssignableUnits?.map(unit => (
                            <option key={unit._id} value={unit._id}>
                              {unit.abbreviation ? `[${unit.abbreviation}] ` : ''}{unit.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span className="col-span-2 text-neutral-300 font-newmedia tracking-[0.1em]">
                          {getUnitName(recordData?.personnelRecord?.unit)}
                        </span>
                      )}
                    </div>
                    {[
                      { label: 'Rank', field: 'rank' },
                      { label: 'Paygrade', field: 'paygrade' },
                      { label: 'Status', field: 'status' },
                      { label: 'Position', field: 'position' },
                      { label: 'Command', field: 'command' },
                      { label: 'Branch', field: 'branch' },
                      { label: 'Callsign', field: 'callsign' },
                    ].map(({ label, field }) => (
                      <div key={field} className="grid grid-cols-3 items-center">
                        <span className="text-neutral-300 font-newmedia tracking-[0.1em]">{label}</span>
                        {isEditing ? (
                          <input
                            type="text"
                            name={field}
                            value={formData[field] || ''}
                            onChange={handleInputChange}
                            className="col-span-2 px-3 py-1.5 bg-neutral-900/50 border border-gray-600 rounded-lg focus:outline-none focus:border-cyan-500/50 text-white"
                          />
                        ) : (
                          <span className="col-span-2 text-neutral-300 font-newmedia tracking-[0.1em]">
                            {recordData?.personnelRecord?.[field] || '-'}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
  
                {/* Dates and Additional Info */}
                <div className="space-y-4">
                  <h3 className="font-semibold text-lg text-cyan-300">Additional Information</h3>
                  <div className="space-y-3">
                    {[
                      { label: 'Time Zone', field: 'tz' },
                      { label: 'Flags', field: 'flags' },
                      { label: 'Platforms', field: 'platforms' },
                      { label: 'Notes', field: 'notes', type: 'textarea' },
                    ].map(({ label, field, type }) => (
                      <div key={field} className="grid grid-cols-3 items-center">
                        <span className="text-neutral-300 font-newmedia tracking-[0.1em]">{label}</span>
                        {isEditing ? (
                          type === 'textarea' ? (
                            <textarea
                              name={field}
                              value={formData[field] || ''}
                              onChange={handleInputChange}
                              className="col-span-2 px-3 py-2 bg-neutral-900/50 border border-gray-600 rounded-lg focus:outline-none focus:border-cyan-500/50 h-24 resize-none text-white"
                            />
                          ) : (
                            <input
                              type="text"
                              name={field}
                              value={formData[field] || ''}
                              onChange={handleInputChange}
                              className="col-span-2 px-3 py-1.5 bg-neutral-900/50 border border-gray-600 rounded-lg focus:outline-none focus:border-cyan-500/50 text-white"
                            />
                          )
                        ) : (
                          <span className="col-span-2 text-neutral-300 font-newmedia tracking-[0.1em]">
                            {recordData?.personnelRecord?.[field] || '-'}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
  
                  {/* Dates */}
                  <div className="pt-4">
                    <h3 className="font-semibold text-lg text-cyan-300 mb-3">Dates</h3>
                    <div className="space-y-3">
                      {[
                        { label: 'Date of Entry', field: 'doe' },
                        { label: 'Date of Rank', field: 'dor' },
                      ].map(({ label, field }) => (
                        <div key={field} className="grid grid-cols-3 items-center">
                          <span className="text-neutral-400">{label}</span>
                          {isEditing ? (
                            <input
                              type="date"
                              name={field}
                              value={formData[field] ? format(new Date(formData[field]), 'yyyy-MM-dd') : ''}
                              onChange={handleInputChange}
                              className="col-span-2 px-3 py-1.5 bg-neutral-900/50 border border-gray-600 rounded-lg focus:outline-none focus:border-cyan-500/50 text-white"
                            />
                          ) : (
                            <span className="col-span-2 text-neutral-300 font-newmedia tracking-[0.1em]">
                              {recordData?.personnelRecord?.[field] 
                                ? format(new Date(recordData.personnelRecord[field]), 'MMM dd, yyyy')
                                : '-'}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};
  
  export default PersonnelFiles;