import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Plus, Shield, Edit2, Trash2 } from 'lucide-react';
import { useQuery, useMutation } from '@apollo/client';
import UnitTree from './UnitTree';
import UnitForm from './UnitForm';
import { GET_UNIT_HIERARCHY, DELETE_UNIT } from '../../../../graphql/queries';
import { AlertContainer } from '../../../ui/alert';
import { Card } from '../../../ui/card';

const UnitManagement = () => {
    const { serverId, officeCode } = useParams();
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');

    const { data: unitData } = useQuery(GET_UNIT_HIERARCHY, {
        variables: { serverId }
    });

    const [deleteUnit] = useMutation(DELETE_UNIT, {
        onCompleted: () => {
            setSelectedUnit(null);
            setIsEditing(false);
        },
        refetchQueries: [
        { query: GET_UNIT_HIERARCHY, variables: { serverId } }
        ],
        onError: (err) => setError(err.message)
    });

    const handleDelete = async () => {
        if (!selectedUnit?._id) return;
        if (window.confirm('Are you sure you want to delete this unit?')) {
        try {
            await deleteUnit({ variables: { id: selectedUnit._id } });
        } catch (err) {
            setError(err.message);
        }
        }
    };

    const canModify = selectedUnit?.serverOwner === serverId;

    return (
        <div className="min-h-screen bg-neutral-900 p-2 sm:p-4 md:p-6 space-y-4">
          <div className="bg-gradient-to-br from-neutral-800/90 to-neutral-900/90 rounded-lg border border-cyan-500/50 p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-neutral-100">Unit Management</h1>
                <p className="text-sm text-neutral-400 mt-1">Manage military unit structure and information</p>
              </div>
              <button
                onClick={() => {
                  setSelectedUnit(null);
                  setIsEditing(true);
                }}
                className="w-full sm:w-auto flex items-center justify-center px-4 py-2 bg-cyan-900/20 border border-cyan-900/50 rounded-lg hover:bg-cyan-800/30"
              >
                <Plus className="w-4 h-4 mr-2" />
                New Unit
              </button>
            </div>
          </div>
    
          {error && (
            <AlertContainer variant="destructive">
              {error}
            </AlertContainer>
          )}
    
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-1 order-2 lg:order-1">
              <UnitTree 
                serverId={serverId}
                onSelectUnit={(unit) => {
                  setSelectedUnit(unit);
                  setIsEditing(false);
                }}
              />
            </div>
    
            <div className="lg:col-span-2 order-1 lg:order-2">
              {isEditing ? (
                <UnitForm
                  serverId={serverId}
                  officeCode={officeCode}
                  unitId={selectedUnit?._id}
                  onComplete={() => {
                    setIsEditing(false);
                    setSelectedUnit(null);
                  }}
                />
              ) : selectedUnit ? (
                <Card className="p-4">
                  <div className="flex flex-col sm:flex-row justify-between items-start gap-4 mb-6">
                    <div className="w-full">
                      <h2 className="text-xl font-bold flex items-center gap-2">
                        {selectedUnit.heraldry?.icon ? (
                          <img src={selectedUnit.heraldry.icon} alt="" className="w-6 h-6" />
                        ) : (
                          <Shield className="w-6 h-6 text-neutral-400" />
                        )}
                        <span className="break-words">{selectedUnit.name}</span>
                      </h2>
                      {selectedUnit.abbreviation && (
                        <p className="text-neutral-400 mt-1">{selectedUnit.abbreviation}</p>
                      )}
                      <div className="flex items-center gap-2 mt-2">
                        <span className="text-neutral-400">Personnel Count:</span>
                        <span className="text-cyan-400 font-semibold">{selectedUnit.personnelCount || 0}</span>
                      </div>
                    </div>
                    {canModify && (
                      <div className="flex gap-2 w-full sm:w-auto">
                        <button
                          onClick={() => setIsEditing(true)}
                          className="flex-1 sm:flex-initial p-2 bg-cyan-900/20 border border-cyan-900/50 rounded-lg hover:bg-cyan-800/30"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                        <button
                          onClick={handleDelete}
                          className="flex-1 sm:flex-initial p-2 bg-red-900/20 border border-red-900/50 rounded-lg hover:bg-red-800/30"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    )}
                  </div>
    
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <h3 className="text-sm font-medium text-neutral-400 mb-1">Category</h3>
                      <p>{selectedUnit.category}</p>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-neutral-400 mb-1">Status</h3>
                      <span className={`inline-block px-2 py-1 text-sm rounded-full ${
                        selectedUnit.currentStatus === 'ACTIVE'
                          ? 'bg-green-900/20 text-green-400'
                          : 'bg-red-900/20 text-red-400'
                      }`}>
                        {selectedUnit.currentStatus}
                      </span>
                    </div>
                    {selectedUnit.designation && (
                      <div>
                        <h3 className="text-sm font-medium text-neutral-400 mb-1">Designation</h3>
                        <p>{selectedUnit.designation}</p>
                      </div>
                    )}
                    <div>
                      <h3 className="text-sm font-medium text-neutral-400 mb-1">Server Owner</h3>
                      <p>{selectedUnit.serverOwner === serverId ? 'This Server' : 'External Server'}</p>
                    </div>
                  </div>
    
                  {selectedUnit.heraldry && (
                    <div className="mt-6 pt-6 border-t border-neutral-700">
                      <h3 className="text-lg font-medium mb-4">Heraldry</h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {selectedUnit.heraldry.emblem && (
                          <div>
                            <h4 className="text-sm font-medium text-neutral-400 mb-2">Emblem</h4>
                            <img 
                              src={selectedUnit.heraldry.emblem}
                              alt="Unit Emblem"
                              className="max-w-[200px] rounded-lg"
                            />
                          </div>
                        )}
                        {selectedUnit.heraldry.color && (
                          <div>
                            <h4 className="text-sm font-medium text-neutral-400 mb-2">Colors</h4>
                            <div 
                              className="w-8 h-8 rounded border border-neutral-600"
                              style={{ backgroundColor: selectedUnit.heraldry.color }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Card>
              ) : (
                <div className="flex items-center justify-center h-32 text-sm text-neutral-400">
                  Select a unit to view details or create a new unit
                </div>
              )}
            </div>
          </div>
        </div>
    );
};

export default UnitManagement;