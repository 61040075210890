import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_STAFF_OFFICES } from '../../graphql/queries';
import styled from 'styled-components';
import { Shield, Users, Eye, FileText, Activity } from 'lucide-react';


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  background: rgba(31, 41, 55, 0.5);
  border-radius: 0.5rem;
  border: 1px solid ${props => props.variant === 'active' ? 'rgba(6, 182, 212, 0.5)' : 'rgba(55, 65, 81, 1)'};
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`;

const IconContainer = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(6, 182, 212, 0.2);

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #22d3ee;
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span:first-child {
    color: white;
  }

  span:last-child {
    font-size: 0.875rem;
    color: #22d3ee;
  }
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: #d1d5db;
  margin-bottom: 1rem;
`;

const FeatureTag = styled.div`
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: rgb(109 137 137 / 10%);
  color: rgb(199 241 247);
  margin-bottom: 0.5rem;
`;

const AccessButton = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #0891b2;
  color: white;
  border-radius: 0.375rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0e7490;
  }
`;

const LoadingSkeleton = styled.div`
  background: rgba(55, 65, 81, 0.5);
  border-radius: 0.25rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
  }
`;

const AlertContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background: ${props => props.variant === 'destructive' ? 'rgba(220, 38, 38, 0.1)' : 'rgba(6, 182, 212, 0.1)'};
  border: 1px solid ${props => props.variant === 'destructive' ? 'rgba(220, 38, 38, 0.2)' : 'rgba(6, 182, 212, 0.2)'};
  color: ${props => props.variant === 'destructive' ? '#ef4444' : '#22d3ee'};
  margin-bottom: 1rem;
`;

const StaffSystem = ({ serverId }) => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_STAFF_OFFICES, {
    variables: { serverId },
    fetchPolicy: 'network-only'
  });

  const getOfficeIcon = (code) => {
    const icons = {
      
      'J1': Users,
      'G1': Users,
      'N1': Users,
      'S1': Users,
      'J2': Eye,
      'G2': Eye,
      'N2': Eye,
      'S2': Eye,
      'J3': Activity,
      'G3': Activity,
      'N3': Activity,
      'S3': Activity,
      'J4': FileText,
      'G4': FileText,
      'N4': FileText,
      'S4': FileText,
      'J5': Shield,
      'G5': Shield,
      'N5': Shield,
      'S5': Shield
    };
    return icons[code] || Shield;
  };

  if (loading) {
    return (
      <Grid>
        {[1, 2, 3].map((i) => (
          <Card key={i}>
            <CardHeader>
              <LoadingSkeleton style={{ width: '2.5rem', height: '2.5rem' }} />
              <div style={{ flex: 1 }}>
                <LoadingSkeleton style={{ width: '5rem', height: '1rem', marginBottom: '0.5rem' }} />
                <LoadingSkeleton style={{ width: '8rem', height: '0.75rem' }} />
              </div>
            </CardHeader>
            <CardContent>
              <LoadingSkeleton style={{ width: '100%', height: '4rem' }} />
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }

  if (error) {
    return (
      <AlertContainer variant="destructive">
        Failed to load staff offices. Please try again later.
      </AlertContainer>
    );
  }

  const offices = data?.staffOffices || [];

  if (offices.length === 0) {
    return (
      <AlertContainer>
        No staff offices are currently available.
      </AlertContainer>
    );
  }

  return (
    <Grid>
      {offices.map((office) => {
        const Icon = getOfficeIcon(office.code);

        return (
          <Card key={office.code} variant="active">
            <CardHeader>
              <IconContainer>
                <Icon />
              </IconContainer>
              <CardTitle>
                <span>{office.code}</span>
                <span>{office.name}</span>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Description>{office.description}</Description>
              {office.features.length > 0 && (
                <div style={{ marginBottom: '1rem' }}>
                  {office.features.map((feature) => (
                    <FeatureTag key={feature}>
                      {feature}
                    </FeatureTag>
                  ))}
                </div>
              )}
              <AccessButton 
                onClick={() => navigate(`/dashboard/${serverId}/staff/${office.code.toLowerCase()}`)}
              >
                Access Office
              </AccessButton>
            </CardContent>
          </Card>
        );
      })}
    </Grid>
  );
};

export default StaffSystem;