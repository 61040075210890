import React, { useState, useCallback } from 'react';
import { useQuery, useApolloClient  } from '@apollo/client';
import { ChevronDown, ChevronRight, Shield } from 'lucide-react';
import { Card } from '../../../ui/card';
import { GET_UNIT_HIERARCHY, GET_UNIT } from '../../../../graphql/queries';


const UnitTree = ({ serverId, onSelectUnit }) => {
  const [expandedUnits, setExpandedUnits] = useState(new Set());
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [unitChildren, setUnitChildren] = useState({});
  const client = useApolloClient();

  const { loading, data } = useQuery(GET_UNIT_HIERARCHY, {
    variables: { serverId },
    fetchPolicy: 'network-only'
  });

  const handleSelectUnit = useCallback((unit) => {
    setSelectedUnitId(unit._id);
    onSelectUnit(unit);
  }, [onSelectUnit]);

  const toggleExpand = useCallback(async (unit, e) => {
    e.stopPropagation();
    const unitId = unit._id;

    if (expandedUnits.has(unitId)) {
      setExpandedUnits(prev => {
        const next = new Set(prev);
        next.delete(unitId);
        return next;
      });
    } else {
      setExpandedUnits(prev => new Set([...prev, unitId]));
      
      // Always fetch fresh data when expanding
      try {
        const response = await client.query({
          query: GET_UNIT,
          variables: { id: unitId },
          fetchPolicy: 'network-only' // Force fresh data
        });
        
        if (response.data.unit?.children) {
          setUnitChildren(prev => ({
            ...prev,
            [unitId]: response.data.unit.children
          }));
          
          // Recursively fetch data for each child
          await Promise.all(response.data.unit.children.map(async (child) => {
            const childResponse = await client.query({
              query: GET_UNIT,
              variables: { id: child._id },
              fetchPolicy: 'network-only'
            });
            if (childResponse.data.unit?.children) {
              setUnitChildren(prev => ({
                ...prev,
                [child._id]: childResponse.data.unit.children
              }));
            }
          }));
        }
      } catch (error) {
        console.error('Error fetching unit children:', error);
      }
    }
  }, [expandedUnits, client]);

  const renderUnit = useCallback((unit) => {
    if (!unit) return null;
    
    const children = unit.children?.length > 0 ? unit.children : unitChildren[unit._id] || [];
    const hasChildren = children?.length > 0;
    const isExpanded = expandedUnits.has(unit._id);
    const isSelected = selectedUnitId === unit._id;

    return (
      <div key={unit._id} className="ml-2">
        <div 
          onClick={() => handleSelectUnit(unit)}
          className={`flex items-start gap-1 py-1 rounded cursor-pointer group
            ${isSelected ? 'bg-neutral-800' : 'hover:bg-neutral-800/50'}`}
        >
          <button
            onClick={(e) => hasChildren && toggleExpand(unit, e)}
            className={`p-1 rounded hover:bg-neutral-700 mt-1 ${!hasChildren && 'invisible'}`}
          >
            {isExpanded ? (
              <ChevronDown className="w-3 h-3" />
            ) : (
              <ChevronRight className="w-3 h-3" />
            )}
          </button>

          <div className="flex-shrink-0 mt-1">
            {unit.heraldry?.emblem ? (
              <img src={unit.heraldry.emblem} alt="" className="w-5 h-5" />
            ) : (
              <Shield className="w-5 h-5 text-neutral-400" />
            )}
          </div>

          <div className="flex flex-col min-w-0 flex-1">
          <div 
                className="font-medium truncate"
                style={{ color: unit.heraldry?.color || '#ffffff' }}
          >{unit.name}</div>
          <span className="text-sm text-neutral-400">
              ({unit.personnelCount || 0})
            </span>
            <div className="flex items-center gap-2 text-sm">
              {unit.abbreviation && (
                <span className="text-neutral-400">{unit.abbreviation}</span>
              )}
              {unit.serverOwner !== serverId && (
                <span className="text-xs text-cyan-400">(Attached)</span>
              )}
              <span className={`px-1.5 py-0.5 text-xs rounded-full ${
                unit.currentStatus === 'ACTIVE' 
                  ? 'bg-green-900/20 text-green-400' 
                  : 'bg-red-900/20 text-red-400'
              }`}>
                {unit.currentStatus}
              </span>
            </div>
          </div>
        </div>

        {isExpanded && hasChildren && (
          <div className="ml-3 border-l border-neutral-800">
            {children.map(child => renderUnit(child))}
          </div>
        )}
      </div>
    );
  }, [expandedUnits, selectedUnitId, unitChildren, handleSelectUnit, toggleExpand]);

  if (loading) return <Card className="p-4"><div className="animate-pulse h-8 bg-neutral-800 rounded" /></Card>;

  return (
    <Card className="p-4">
      {data?.unitHierarchy?.map(unit => renderUnit(unit)) || (
        <div className="text-center text-neutral-400 py-4">No units found</div>
      )}
    </Card>
  );
};

export default UnitTree;