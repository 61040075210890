import React, { useState } from 'react';

import { DropdownHeader, DropdownItem, DropdownItemContainer } from './styles';


const Dropdown = ({ options, value, onChange }) => {
    const [expanded, setExpanded] = useState(false);
  
    return (
      <div>
        <DropdownHeader expanded={expanded} onClick={() => setExpanded(!expanded)}>
          {value || 'All Platforms'}
        </DropdownHeader>
        <DropdownItemContainer expanded={expanded}>
          {options.map(option => (
            <DropdownItem
              key={option}
              isSelected={value === option}
              onClick={() => {
                onChange(option);
                setExpanded(false);
              }}
            >
              {option}
            </DropdownItem>
          ))}
        </DropdownItemContainer>
      </div>
    );
  };

export default Dropdown;